import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import { ArticleJsonLd, SpeakableJsonLd } from 'gatsby-plugin-next-seo'

import { useTheme } from '@material-ui/core/styles'

import { createTocList } from '@tools/componentParser'
import ContenfulComponentArray from '@tools/ContentfulComponentArray'
import { GlossaryScopeProvider } from '@providers/glossaryScopeProvider'

import Container from '@objects/container'
import Copy from '@objects/copy'
import Headline from '@objects/headline'
import Sources from '@objects/sources'
import TopicTeaserGrid from '@components/topicTeaserGrid'
import ToC from '@components/toc'
import DialogTeaserCard from '@components/dialogTeaserCard'
import ImageCard from '@components/imageCard'

function TopicArticle({ pageContext, data, pathContext }) {
  const theme = useTheme()
  const intl = useIntl()

  const articletitle = data.contentfulArticle?.title
  const createdAt =
    data.contentfulArticle?.date ?? data.contentfulArticle?.createdAt
  const stageImg = data.contentfulArticle?.stageImageWithFocalpoint
    ? data.contentfulArticle?.stageImageWithFocalpoint.image
    : data.contentfulArticle?.stageImage
  const stageImgFocalPoint =
    data.contentfulArticle?.stageImageWithFocalpoint?.focalPoint
  const articlecontent = data.contentfulArticle?.copy?.json?.content
  const introCopy = data.contentfulArticle?.introCopy
  const articlesources = data.contentfulArticle?.sources
  const relatedArticles = data.contentfulArticle?.relatedArticles
  const topic___category = data.contentfulArticle?.topic___category

  const articleintro = introCopy?.childMarkdownRemark?.html
  const topicCategory = topic___category?.length
    ? topic___category[0]?.title
    : ''

  const relatedArticlesMapped = relatedArticles?.map((article) => {
    const cat = !!article.topic___category ? article.topic___category : null
    const path =
      cat === null
        ? intl.formatMessage({ id: 'press.article.path' })
        : cat[0].topic___category___list?.[0].identifier === 'magazin'
        ? intl.formatMessage({ id: 'magazine.path' })
        : `${intl.formatMessage({ id: 'inform.path' })}${cat[0].slug}/`

    return {
      headline: article?.title,
      copy: article.teaserCopy?.childMarkdownRemark?.html,
      image: article.teaserImageWithFocalpoint
        ? article.teaserImageWithFocalpoint.image.fluid
        : article.teaserImage?.fluid,
      imageAlt: article.teaserImageWithFocalpoint
        ? article.teaserImageWithFocalpoint.image.description
        : article.teaserImage?.description,
      focalPoint: article.teaserImageWithFocalpoint?.focalPoint,
      slug: article.slug ? `${path}${article.slug}/` : article.url,
      url: !!article.url && article.url,
    }
  })

  let tocItems = articleintro
    ? [
        {
          id: intl
            .formatMessage({ id: 'article.intro' })
            .replace(/\s+/g, '')
            .toLowerCase(),
          label: intl.formatMessage({ id: 'article.intro' }),
        },
      ].concat(createTocList(articlecontent, pageContext.locale))
    : createTocList(articlecontent, pageContext.locale)

  if (!tocItems) {
    tocItems = []
  }
  if (articlesources && tocItems?.length) {
    tocItems.push({
      id: intl
        .formatMessage({ id: 'article.sources' })
        .replace(/\s+/g, '')
        .toLowerCase(),
      label: intl.formatMessage({ id: 'article.sources' }),
    })
  }

  return (
    <React.Fragment>
      <ArticleJsonLd
        url={pathContext.canonical}
        headline={articletitle}
        datePublished={createdAt}
        overrides={{
          '@type': 'NewsArticle',
        }}
      />
      <SpeakableJsonLd cssSelector={['article']} />
      <ImageCard
        image={stageImg}
        focalPoint={stageImgFocalPoint}
        subline={topicCategory}
        headline={articletitle}
        parallax
      />
      <ToC items={tocItems}>
        {articleintro && (
          <Container id={tocItems[0].id} sidebar>
            <Copy type="intro">{articleintro}</Copy>
          </Container>
        )}
        {articlecontent && (
          <GlossaryScopeProvider>
            <ContenfulComponentArray componentarray={articlecontent} toc />
          </GlossaryScopeProvider>
        )}
        {articlesources && !!tocItems?.length && (
          <Container sidebar type="article">
            <Headline id={tocItems[tocItems.length - 1].id}>
              {intl.formatMessage({ id: 'article.sources' })}
            </Headline>
            <Sources items={articlesources} />
          </Container>
        )}
      </ToC>
      <DialogTeaserCard
        headline={intl.formatMessage({ id: 'teaser.onlinedialog.headline' })}
        copy={intl.formatMessage({ id: 'teaser.onlinedialog.copy' })}
        buttons={[
          {
            label: intl.formatMessage({ id: 'button.onlinedialog' }),
            link: intl.formatMessage({ id: 'dialog.path' }),
          },
          {
            label: intl.formatMessage({ id: 'button.createArticle' }),
            color: 'outline',
            create: true,
          },
        ]}
      />
      {relatedArticles && (
        <Container padded background={theme.palette.background.grey}>
          <TopicTeaserGrid
            headline={`${intl.formatMessage({
              id: 'article.related',
            })} ${topicCategory}`}
            headlineLevel={3}
            topics={relatedArticlesMapped}
            gridMap={
              relatedArticles.length === 2
                ? [6, 6, 6, 6, 6, 6, 6]
                : [4, 4, 4, 4, 4, 4, 4]
            }
            shadow={false}
          />
        </Container>
      )}
    </React.Fragment>
  )
}

TopicArticle.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default TopicArticle

export const pageQuery = graphql`
  query TopicArticleBySlug(
    $slug: String!
    $locale: String!
    $contentful_id: String!
  ) {
    SlugByContentfulId: contentfulArticle(
      contentful_id: { eq: $contentful_id }
      node_locale: { ne: $locale }
    ) {
      slug
      node_locale
      fields {
        fullPath
      }
      topic___category {
        slug
      }
    }
    contentfulArticle(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      node_locale
      fields {
        fullPath
      }
      createdAt
      date
      naviTitle
      title
      introCopy {
        childMarkdownRemark {
          html
        }
      }
      copy {
        json
      }
      sources {
        description
        label
        url
      }
      topic___category {
        slug
        title
      }
      stageImage {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
        title
      }
      stageImageWithFocalpoint {
        image {
          fluid(maxWidth: 2000, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
          title
        }
        focalPoint
      }
      relatedArticles {
        ... on ContentfulArticle {
          id
          slug
          title
          updatedAt
          teaserCopy {
            childMarkdownRemark {
              html
            }
          }
          teaserImage {
            fluid(maxWidth: 604, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          teaserImageWithFocalpoint {
            image {
              fluid(maxWidth: 704, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            focalPoint
          }
          topic___category {
            slug
            topic___category___list {
              identifier
            }
          }
        }
        ... on ContentfulPageExternal {
          id
          titleInternal
          url
          teaserTitle
          teaserCopy {
            childMarkdownRemark {
              html
            }
          }
          teaserImage {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
            title
          }
        }
      }
    }
  }
`
